import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/VaknerAssets.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Vakner-Intel",
      description: "Plataforma de ciberinteligencia OT no intrusiva",
      imgUrl: projImg1,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Nuestros productos</h2>
                <p>Desarrollamos herramientas para la automatización de la ciberseguridad industrial.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Vakner-Intel</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Más...</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                        <p >Vakner-Intel cuenta con diversas funcionalidades de inteligencia externa e interna, con herramientas de IA, para la ciberseguridad industrial de cualquier empresa.
                        <br></br>
                        <br></br>&#10003; Automatizar la generación de análisis de riesgos industriales.
                        <br></br>&#10003; Detectar y consultar proactivamente vulnerabilidades OT.
                        <br></br>&#10003; Recibir notificaciones periódicas de nuevas vulnerabilidades que puedan afectar a tus activos industriales.
                        <br></br>&#10003; Analizar proactivamente posibles campañas de phishings dirigidas a su empresa, para estar preparado ante futuros ataques.
                        <br></br>&#10003; Trasladar consultas a Vakner para analizar posibles ataques de ingeniería social.
                        <br></br>&#10003; Identificar filtraciones de credenciales.
                        <br></br>&#10003; Identificar activos de su planta industrial expuestos a internet.
                        <br></br>&#10003; Conocer aspectos fundamentales de inteligencia sobre su dominio en internet.</p>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>En proceso de creación.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
