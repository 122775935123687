import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/binario.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});
  const [isSent, setIsSent] = useState(false);


  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch("http://localhost:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Mensaje enviado correctamente'});
    } else {
      setStatus({ succes: false, message: 'Algo ha salido mal. inténtalo más tarde.'});
    }
  };

  const form = useRef();

  const state = {
    showMessage: false,
    message:''
  };

  const sendEmail = (e) => {
    e.preventDefault();
    state.showMessage = true
    state.message ='Mensaje enviado correctamente ¡Muchas gracias!'
    emailjs.sendForm('service_xgpn9md', 'template_e22l0x7', form.current, 'RgzzIDA8coFgYNfSR')
      .then((result) => {
          console.log(result.text);
          //state.showMessage = true;
          //state.message ='Mensaje enviado correctamente ¡Muchas gracias!';
      }, (error) => {
          console.log(error.text);
          //state.showMessage = true;
          //state.message ='Error al enviar el mensaje. Lo sentimos...';
      });
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {//({ isVisible }) =>
                //<img src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div>
                <h2>Contacta con nosotros</h2>
                <form ref={form} onSubmit={sendEmail}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="nombre" value={formDetails.firstName} placeholder="Nombre" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="apellidos" value={formDetails.lastName} placeholder="Apellidos" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="email" value={formDetails.email} placeholder="Correo electrónico" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="telefono" value={formDetails.phone} placeholder="Teléfono" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="mensaje" value={formDetails.message} placeholder="Mensaje" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button
                        type="submit"
                        onClick={() => {
                          setIsSent(true); // Cambiar el estado a "Enviado"
                        }}
                      >
                        <span>
                          {isSent ? '¡Enviado!' : buttonText}
                        </span>
                      </button>
                      <p></p>
                      <p style={{ color: isSent ? 'white' : 'white' }}>
                        {isSent ? state.message : state.message}
                      </p>
                    </Col>
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
